<template>
  <model ref="model">
    <div class="gzh-dialog">
      <div class="title">第一步</div>
      <div class="desc">在电脑浏览器上输入</div>
      <div class="url">www.001ppt.com</div>
      <div class="copy-btn" @click="copy">复制网址</div>

      <div class="pc">
        <div class="pc-box">
          <div class="toolbar">
            <div class="toolbar-btn-box">
              <div class="toolbar-btn toolbar-btn-red"></div>
              <div class="toolbar-btn toolbar-btn-yellow"></div>
              <div class="toolbar-btn toolbar-btn-green"></div>
            </div>
            <div class="toolbar-search">
              <div class="toolbar-search-content">www.001ppt.com</div>
              <i class="iconfont icon-search search-icon"></i>
            </div>
          </div>
          <div class="logo">
            <img class="logo-image" src="https://cdn.001ppt.cn/assets/img/logo.svg" alt/>
            <van-icon name="checked" class="logo-check-icon"/>
          </div>
          <div class="scan">
            <div class="scan-text">点击右上角"快速登录"</div>
            <div class="scan-qr">
              <img src="https://cdn.001ppt.cn/assets/img/fake_qr.png" alt/>
            </div>
          </div>
        </div>
      </div>

      <div class="rpxBorder"></div>
      <div class="title title2">第二步</div>
      <div class="desc">使用 "微信扫一扫" 登录</div>
      <!--<div class="primary-btn" bindtap="showScan">打开扫一扫</div>-->
    </div>

  </model>
</template>


-------------------- script --------------------

<script>
  import model from '@/components/model/model'
  import {copyToClipBoard} from "../config/util";

  export default {
    components: {
      model
    },

    data() {
      return {
        visible: false
      }
    },

    methods: {

      show() {
        // this.$refs.model.toggleDialog();
        window.location.href = 'https://www.001ppt.com/urlScheme?remark=首页展示复制到PC&path=pages/tabbar/home/home&query=showGoPcDialog=true';
      },

      copy() {
        copyToClipBoard('https://www.001ppt.com');
        this.$toast.success('复制成功');
      }

    }
  }
</script>


-------------------- style --------------------

<style scoped lang="less">
  .rpxBorder {
    background-color: rgba(21, 29, 54, 0.03);
    width: 100%;
    height: 1px;
    margin-top: 32px;
    margin-bottom: 20px;
  }

  .primary-btn {
    width: 270px;
    height: 45px;
    background: #151d36;
    color: #fff;
    border-radius: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    margin-top: 8px;
  }

  .title{
    font-size: 20px;
    text-align: center;
    font-weight: bolder;
    margin-bottom: 24px;
  }

  .desc{
    text-align: center;
    font-size: 12px;
  }

  .url{
    font-size: 20px;
    text-align: center;
    margin: 0 0 6px 0;
  }

  .copy-btn{
    font-size: 12px;
    margin: 0 auto;
    width: 75px;
    padding: 4px 0;
    border-radius: 50px;
    text-align: center;
    border: 1px solid #000;
  }

  .pc{
    display: flex;
    justify-content: center;
    margin: 16px 0;
  }

  .pc-box{
    width: 270px;
    height: 162px;
    background-color: rgba(21, 29, 54, 0.03);
  }

  .toolbar{
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .toolbar-btn-box{
    display: flex;
    align-items: center;
    margin-left: 7px;
  }

  .toolbar-btn{
    height: 5px;
    width: 5px;
    border-radius: 50px;
    margin-left: 10px;
  }

  .toolbar-btn-red{
    background: #ff6059;
  }

  .toolbar-btn-yellow{
    background: #ffbe2f;
  }

  .toolbar-btn-green{
    background: #29c941;
  }

  .toolbar-search{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    height: 18px;
    border-radius: 150px;
    font-size: 12px;
    color: rgba(80, 80, 80, 1);
    background-color: #fff;
    margin-left: 10px;
    margin-right: 8px;
  }

  .toolbar-search-content{
    color: rgba(21, 29, 54, 1);
    padding-left: 12px;
    opacity: 0.3;
    transform: translateY(-1px);
  }

  .search-icon{
    font-size: 10px;
    opacity: 0.3;
    margin-right: 8px;
  }

  .logo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 18px;
  }

  .logo-image{
    width: 70px;
    height: 14px;
  }

  .logo-check-icon{
    font-size: 14px;
  }


  .scan{
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  .scan-text{
    font-size: 12px;
    color: rgba(21, 29, 54, 1);
    margin-right: 8px;
  }

  .scan-qr{
    opacity: .3;
  }

  .scan-qr img{
    height: 75px;
    width: 75px;
  }



</style>
