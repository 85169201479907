<template>
  <div class="wallet-page" :class="{'can-cash-out': cashOutConfig.minCashOut<=wallet.remainProfitValue}">
    <v-header/>
    <div class="wallet-card">
      <div class="record-btn" @click="goRecord">
        收支记录 <van-icon name="arrow"/>
      </div>
      <div class="user-info">
        <img class="avatar" :src="userInfo.avatar" alt="">
        <div class="nickName">{{userInfo.username}}</div>
      </div>
      <div class="wallet-remain">
        <div class="wallet-label">总严值余额</div>
        <div class="wallet-value">{{yanzhiFilter(wallet.totalRemainValue)}}</div>
      </div>
      <div class="wallet-cash-out">
        <div class="wallet-label">收益严值余额</div>
        <div class="wallet-value">{{yanzhiFilter(wallet.remainProfitValue)}}</div>
      </div>


      <div class="recharge-btn" @click="goRecharge">充值</div>
      <div class="cash-out-btn" @click="goCashOut">提现</div>
    </div>


    <div class="introduce">
      <van-collapse v-model="collapse">
        <van-collapse-item name="gain">
          <template #title>严值怎么获取？</template>
          <template #default>
           <div class="yanzhi-gain">
             <div class="yanzhi-gain-item">
               <img class="yanzhi-gain-item-icon" src="https://cdn.001ppt.cn/h5/static/image/wallet-gain-task.png">
               <div class="yanzhi-gain-item-info">
                 <div class="yanzhi-gain-item-name">完成任务赚奖励</div>
                 <div class="yanzhi-gain-item-desc">达标后还可升级增加倍数</div>
               </div>
               <div class="yanzhi-gain-item-btn" @click="goTask">前往完成</div>
             </div>
             <div class="yanzhi-gain-item">
               <img class="yanzhi-gain-item-icon" src="https://cdn.001ppt.cn/h5/static/image/wallet-gain-profit.png">
               <div class="yanzhi-gain-item-info">
                 <div class="yanzhi-gain-item-name">上传方案赚收益</div>
                 <div class="yanzhi-gain-item-desc">上传有奖励下载有收益</div>
               </div>
               <div class="yanzhi-gain-item-btn" @click="$refs.goPcDialog.show()">前往电脑端</div>
             </div>
             <div class="yanzhi-gain-item">
               <img class="yanzhi-gain-item-icon" src="https://cdn.001ppt.cn/h5/static/image/wallet-gain-recharge.png">
               <div class="yanzhi-gain-item-info">
                 <div class="yanzhi-gain-item-name">自行充值获严值</div>
                 <div class="yanzhi-gain-item-desc">高效省事充越多送越多</div>
               </div>
               <div class="yanzhi-gain-item-btn" @click="goRecharge">前往充值</div>
             </div>
           </div>
          </template>
        </van-collapse-item>
        <van-collapse-item name="use">
          <template #title>严值有什么用？</template>
          <template #default>
            <div class="yanzhi-use">
              <div class="yanzhi-use-item split">
                <img class="yanzhi-use-item-img" src="https://cdn.001ppt.cn/h5/static/image/wallet-cart.png">
                <div class="yanzhi-use-item-text">
                  <div>抵扣严值</div>
                  <div>购买方案</div>
                </div>
              </div>
              <div class="yanzhi-use-item split">
                <img class="yanzhi-use-item-img" src="https://cdn.001ppt.cn/h5/static/image/wallet-badge.png">
                <div class="yanzhi-use-item-text">
                  <div>抵扣严值</div>
                  <div>续费会员</div>
                </div>
              </div>
              <div class="yanzhi-use-item">
                <img class="yanzhi-use-item-img" src="https://cdn.001ppt.cn/h5/static/image/wallet-wallet.png">
                <div class="yanzhi-use-item-text">
                  <div>收益严值</div>
                  <div>支持提现</div>
                </div>
              </div>
            </div>
          </template>
        </van-collapse-item>
      </van-collapse>
    </div>


    <go-pc-dialog ref="goPcDialog"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {cashOutV2Api} from "../../api/v2/cashOutV2Api";
import GoPcDialog from "../../components/goPcDialog";

export default {
  components: {GoPcDialog},
  computed:{
    ...mapGetters(['userInfo','wallet'])
  },


  data(){
    return {
      collapse:['use'],
      cashOutConfig: {
        minCashOut: 0,     // 最少提现
        maxCashOut: 0,     // 最多提现
        maxEverydayTimes: 0 // 每天提现次数
      },
    }
  },


  created(){
    this.$store.dispatch('getWallet')
    this.loadCashOutConfig()
  },


  methods:{
    goRecord(){
      this.$router.push('/record?tab=CONSUME')
    },

    goRecharge(){
      this.$router.push('/topUpMoney')
    },

    goCashOut(){
      const {cashOutConfig} = this
      const {remainProfitValue} = this.wallet
      if(cashOutConfig.minCashOut > remainProfitValue){
        return this.$toast.info(`满¥${cashOutConfig.minCashOut/10}即可提现`)
      }
      this.$router.push('/pickUpMoney')
    },

    // 加载最少提现金额
    async loadCashOutConfig() {
      this.cashOutConfig = await cashOutV2Api.findConfig();
    },

    // 前往任务页
    goTask(){
      this.$router.push('/rank?tab=task')
    }

  }
}
</script>

<style scoped lang="less">
.wallet-page{
  background: #f7f7f7;
  padding-top: 40px;
  min-height: calc(100vh - 40px);
}

.wallet-card{
  width: 356px;
  height: 182px;
  background-size: cover;
  background-image: url("https://cdn.001ppt.cn/h5/static/image/wallet-no-cash-out.png");
  margin: 12px 10px 0 9px;
  position: relative;
}


.user-info{
  position: absolute;
  top: 20px;
  left: 19px;
  display: flex;
  align-items: center;
}

.avatar{
  height: 25px;
  width: 25px;
  border-radius: 100px;
}

.nickName{
  height: 19px;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  line-height: 19px;
  padding-left: 5px;
}

.wallet-remain{
  position: absolute;
  top: 60px;
  left: 20px;
}

.wallet-remain .wallet-label{
  height: 18px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
}

.wallet-remain .wallet-value{
  height: 34px;
  color: #fff;
  font-size: 18px;
  line-height: 34px;
  font-weight: bold;
}

.recharge-btn{
  position: absolute;
  width: 90px;
  height: 25px;
  left: 19px;
  top: 143px;
  color: rgba(80, 80, 80, 1);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgb(80,80,80);
  border-radius: 13px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.record-btn{
  position: absolute;
  top: 10px;
  right: 1px;
  width: 70px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  background: #fff;
  font-size: 10px;
  padding-left: 6px;
}

.wallet-cash-out{
  position: absolute;
  top: 60px;
  left: 226px;
  opacity: .3;
}


.wallet-cash-out .wallet-label{
  height: 18px;
  color: #151D36;
  font-size: 10px;
  line-height: 18px;
}

.wallet-cash-out .wallet-value{
  height: 34px;
  color: #151D36;
  font-size: 18px;
  line-height: 34px;
  font-weight: bold;
}

.cash-out-btn{
  width: 90px;
  height: 25px;
  color: rgba(80, 80, 80, 1);
  background-color: rgba(21, 29, 54, 1);
  border: 1px solid  rgba(21, 29, 54, 1);
  border-radius: 13px;
  text-align: center;
  top: 143px;
  left: 235px;
  position: absolute;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: .5;
}

.introduce{
  margin-top: 20px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
}

.yanzhi-use{
  display: flex;
  align-items: center;
  //padding: 0 36px 0 29px;
  opacity: .5;
  padding: 13px 0;
}

.yanzhi-use-item{
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yanzhi-use-item.split{
  position: relative;
}

.yanzhi-use-item.split::after{
  content: '';
  position: absolute;
  height: 28px;
  width: 1px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #151D36aa;
}

.yanzhi-use-item-img{
  height: 30px;
  width: 30px;
}

.yanzhi-use-item-text{
  width: 60px;
  height: 36px;
  color: rgba(21, 29, 54, 1);
  font-size: 12px;
  text-align: center;
}

.can-cash-out .wallet-cash-out{
  opacity: 1;
}

.can-cash-out .cash-out-btn{
  opacity: 1;
  color: #fee09f;
}

.can-cash-out .wallet-card{
  background-image: url("https://cdn.001ppt.cn/h5/static/image/wallet-can-cash-out.png");
}

.yanzhi-gain-item{
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.yanzhi-gain-item-icon{
  height: 50px;
  width: 50px;
  flex-shrink: 0;
}

.yanzhi-gain-item-info{
  padding-left: 10px;
  flex-grow: 1;
  flex-shrink: 0;
}

.yanzhi-gain-item-name{
  height: 18px;
  color: rgba(21, 29, 54, 1);
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
}

.yanzhi-gain-item-desc{
  height: 18px;
  opacity: 0.3;
  color: rgba(21, 29, 54, 1);
  font-size: 12px;
  line-height: 18px;
}

.yanzhi-gain-item-btn{
  flex-shrink: 0;
  width: 100px;
  height: 25px;
  color: rgba(80, 80, 80, 1);
  border: 1px solid #151D36;
  border-radius: 13px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yanzhi-gain-item-btn.disabled{
  opacity: .5;
}


</style>
